import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/docs.tsx";
export const pageTitle = "Using Armeria with Spring WebFlux";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Tip = makeShortcode("Tip");
const RequiredDependencies = makeShortcode("RequiredDependencies");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "using-armeria-with-spring-webflux",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#using-armeria-with-spring-webflux",
        "aria-label": "using armeria with spring webflux permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using Armeria with Spring WebFlux`}</h1>
    <Tip mdxType="Tip">
      <p>{`Visit `}<a parentName="p" {...{
          "href": "https://github.com/line/armeria-examples"
        }}>{`armeria-examples`}</a>{` to find a fully working example.`}</p>
    </Tip>
    <p>{`Spring framework provides powerful features which are necessary for building a web application, such as
dependency injection, data binding, AOP, transaction, etc. In addition, if your Spring application integrates
with Armeria, you can leverage the following:`}</p>
    <ul>
      <li parentName="ul">{`Rich support for Apache `}<a parentName="li" {...{
          "href": "https://thrift.apache.org/"
        }}>{`Thrift`}</a>{` and `}<a parentName="li" {...{
          "href": "https://grpc.io/"
        }}>{`gRPC`}</a>{`,
including `}<a parentName="li" {...{
          "href": "/docs/server-docservice"
        }}>{`the fancy web console`}</a>{` that enables you to send Thrift and gRPC requests
from a web browser`}</li>
      <li parentName="ul">{`Ability to run HTTP REST service and RPC service in the same port`}</li>
      <li parentName="ul">{`Full HTTP/2 support for both server-side and client-side, including `}<inlineCode parentName="li">{`h2c`}</inlineCode>{` (plaintext HTTP/2)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.haproxy.org/download/1.8/doc/proxy-protocol.txt"
        }}>{`PROXY protocol`}</a>{` support which provides
interoperability with load balancers such as `}<a parentName="li" {...{
          "href": "https://www.haproxy.org/"
        }}>{`HAProxy`}</a>{` and
`}<a parentName="li" {...{
          "href": "https://aws.amazon.com/elasticloadbalancing/"
        }}>{`AWS ELB`}</a></li>
    </ul>
    <p>{`Armeria can be plugged in as the underlying HTTP server for a Spring Boot 2 WebFlux application by adding
the following dependency:`}</p>
    <RequiredDependencies boms={[{
      groupId: 'com.linecorp.armeria',
      artifactId: 'armeria-bom'
    }, {
      groupId: 'io.netty',
      artifactId: 'netty-bom'
    }]} dependencies={[{
      groupId: 'com.linecorp.armeria',
      artifactId: 'armeria-spring-boot2-webflux-starter'
    }]} mdxType="RequiredDependencies" />
    <p>{`The above starter configures Armeria as the HTTP server for WebFlux to run on by referring to `}<inlineCode parentName="p">{`application.yml`}</inlineCode>{`
when the application starts up. A user can customize the server configuration with the same properties
provided by Spring Boot as for other servers supported with WebFlux such as Tomcat or Reactor Netty.
The following is a simple example for configuring the server:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`server:
  address: 127.0.0.1
  port: 8080
`}</code></pre>
    <p>{`For a user who wants to customize the web server, `}<a parentName="p" {...{
        "href": "type://ArmeriaServerConfigurator:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/spring/ArmeriaServerConfigurator.html"
      }}>{`type://ArmeriaServerConfigurator`}</a>{` is provided.
The user can customize the server by defining a bean of the type in the configuration as follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`@Configuration
public class ArmeriaConfiguration {
    // A user can configure the server by providing an ArmeriaServerConfigurator bean.
    @Bean
    public ArmeriaServerConfigurator armeriaServerConfigurator() {
        // Customize the server using the given ServerBuilder. For example:
        return builder -> {
            // Add DocService that enables you to send Thrift and gRPC requests
            // from web browser.
            builder.serviceUnder("/docs", new DocService());

            // Log every message which the server receives and responds.
            builder.decorator(LoggingService.newDecorator());

            // Write access log after completing a request.
            builder.accessLogWriter(AccessLogWriter.combined(), false);

            // You can also bind asynchronous RPC services such as Thrift and gRPC:
            // builder.service(THttpService.of(...));
            // builder.service(GrpcService.builder()...build());
        };
    }
}
`}</code></pre>
    <p>{`Armeria can also be plugged as the underlying HTTP client for the Spring `}<inlineCode parentName="p">{`WebClient`}</inlineCode>{`. To customize
client settings for the Armeria HTTP client, simply define an `}<a parentName="p" {...{
        "href": "type://ArmeriaClientConfigurator:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/spring/web/reactive/ArmeriaClientConfigurator.html"
      }}>{`type://ArmeriaClientConfigurator`}</a>{` bean
in your configuration as follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`@Configuration
public class ArmeriaConfiguration {
    // Returns a custom ClientFactory with TLS certificate validation disabled,
    // which means any certificate received from the server will be accepted
    // without any verification. It is used for an example which makes the client
    // send an HTTPS request to the server running on localhost with a self-signed
    // certificate. Do NOT use ClientFactory.insecure() or
    // ClientFactoryBuilder.tlsNoVerify() in production.
    @Bean
    public ClientFactory clientFactory() {
        return ClientFactory.insecure();
    }

    // A user can configure a Client by providing an ArmeriaClientConfigurator bean.
    @Bean
    public ArmeriaClientConfigurator
            armeriaClientConfigurator(ClientFactory clientFactory) {

        // Customize the client using the given WebClientBuilder. For example:
        return builder -> {
            // Use a circuit breaker for each remote host.
            final CircuitBreakerRule rule =
                    CircuitBreakerRule.onServerErrorStatus();
            builder.decorator(CircuitBreakerClient.builder(rule)
                                                  .newDecorator());

            // Set a custom client factory.
            builder.factory(clientFactory);
        };
    }
}
`}</code></pre>
    <Tip mdxType="Tip">
      <p>{`If you are not familiar with Spring Boot and Spring WebFlux, please refer to
`}<a parentName="p" {...{
          "href": "https://docs.spring.io/spring-boot/docs/current/reference/html/"
        }}>{`Spring Boot Reference Guide`}</a>{` and
`}<a parentName="p" {...{
          "href": "https://docs.spring.io/spring/docs/current/spring-framework-reference/web-reactive.html"
        }}>{`Web on Reactive Stack`}</a>{`.`}</p>
    </Tip>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      